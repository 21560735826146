'use strict';
jQuery(document).ready(function($){
    $('.slides').slick({
        infinite: true,
        autoplay: true,
        fade: true,
        dots:true,
        pauseOnHover: false
      });

});